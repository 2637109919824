import http from '@/libs/http'
import axios from 'axios'
import Cookies from 'js-cookie'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RECEPCION_PEDIDOS
export default class RecepcionPedidosService {
  paginate (params) {
    return http.get(`${baseUrl}/pedidos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getProveedores () {
    return http.get(`${baseUrl}/pedidos/getProveedores`, {
      headers: {
        loading: true
      }
    })
  }

  getBodegas () {
    return http.get(`${baseUrl}/pedidos/getBodegas`, {
      headers: {
        loading: true
      }
    })
  }

  getDetails (payload) {
    return http.get(`${baseUrl}/pedidos/getDetails/${payload}`, {
      headers: {
        loading: true
      }
    })
  }

  getRecepcionDetails (payload) {
    return http.get(`${baseUrl}/recepcion/get-detail/${payload.DocEntry}/${payload.previewEntrada}`, {
      headers: {
        loading: true
      }
    })
  }

  getLinesMx (payload) {
    return http.get(`${baseUrl}/pedidos/getLineasPedido/${payload.pedidos}`, {
      params: { ...payload },
      headers: {
        loading: true
      }
    })
  }

  createRecepcion (payload) {
    return http.post(`${baseUrl}/recepcion/create-recepcion/`, payload, {
      headers: {
        loading: true
      }
    })
  }

  getBatchNumber (lote, ItemCode) {
    return http.get(`${baseUrl}/pedidos/getBatchNumber/${lote}/${ItemCode}`, {
      headers: {
        loading: true
      }
    })
  }

  getUbicaciones () {
    return http.get(`${baseUrl}/recepcion/get-ubicaciones`, {
      headers: {
        loading: false
      }
    })
  }

  getRecepciones (params) {
    return http.get(`${baseUrl}/recepcion/get-recepciones`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getAllMds (payload) {
    return http.get(`${baseUrl}/medicamento/search-mx`, {
      params: { search: payload },
      headers: {
        loading: true
      }
    })
  }

  updateLineaRecepcion (payload) {
    return http.put(`${baseUrl}/recepcion/update-linea`, payload, {
      headers: {
        loading: true
      }
    })
  }

  enviarCompras (payload) {
    return http.put(`${baseUrl}/recepcion/send-compras/${payload}`, {
      headers: {
        loading: true
      }
    })
  }

  getJsonBodega (payload) {
    return http.get(`${baseUrl}/recepcion/get-json-bodega/${payload}`, {
      headers: {
        loading: true
      }
    })
  }

  getEntradasMercancias (params) {
    return http.get(`${baseUrl}/entrada/get-entradas`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getDocumentoDevolucion (DocNum) {
    return http.get(`${baseUrl}/entrada/get-documento-devolucion/${DocNum}`, {
      headers: {
        loading: true
      }
    })
  }

  getEntradaDetails (payload) {
    return http.get(`${baseUrl}/entrada/get-entradas-detail/${payload}`, {
      headers: {
        loading: true
      }
    })
  }

  postSap (DocEntry) {
    return http.post(`${baseUrl}/entrada/confirm-entrada/${DocEntry}`, {
      headers: {
        loading: true
      }
    })
  }

  updateBarCode (payload) {
    return http.post(`${baseUrl}/medicamento/update-barCode`, payload, {
      headers: {
        loading: true
      }
    })
  }

  asignarUsuarioRecep (DocEntry, payload) {
    return http.put(`${baseUrl}/recepcion/asignar-usuario/${DocEntry}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  updateDiscount (DocEntry, payload) {
    return http.put(`${baseUrl}/recepcion/update-discount/${DocEntry}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  imprimirPdfEntrada = (DocNum) => {
    const url = `${baseUrl}/entrada/generarPdf/${DocNum}`
    const token = JSON.parse(Cookies.get('user')).token
    const res = axios.getUri({ url, params: { token } })
    window.open(res, '_blank')
  }

  imprimirPdfPedido = (DocNum) => {
    const url = `${baseUrl}/pedidos/getPdfPedido/${DocNum}`
    const token = JSON.parse(Cookies.get('user')).token
    const res = axios.getUri({ url, params: { token } })
    window.open(res, '_blank')
  }

  imprimirPdfDistribucion = (BaseRef, LineNum, payload) => {
    const url = `${baseUrl}/recepcion/get-pdf-distribucion/${BaseRef}/${LineNum}`
    const token = JSON.parse(Cookies.get('user')).token
    const res = axios.getUri({ url, params: { token, payload } })
    window.open(res, '_blank')
  }

  cancelarPedido = (DocEntry) => {
    return http.post(`${baseUrl}/pedidos/cancelar-pedido/${DocEntry}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  deleteLote = (idLote, idsNovedades) => {
    return http.put(`${baseUrl}/recepcion/delete-lote/${idLote}`, { idsNovedades }, {
      headers: {
        loading: true
      }
    })
  }

  deleteCarta = (idLote) => {
    return http.put(`${baseUrl}/recepcion/delete-carta/${idLote}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  getTiposNovedades = () => {
    return http.get(`${baseUrl}/novedades/get-tipos`, {}, {
      headers: {
        loading: true
      }
    })
  }

  getDetallesNovedades = (idTipo) => {
    return http.get(`${baseUrl}/novedades/get-detalles/${idTipo}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  verPdf = (id) => {
    const url = `${baseUrl}/recepcion/ver-pdf/${id}`
    const token = JSON.parse(Cookies.get('user')).token
    const res = axios.getUri({ url, params: { token } })
    window.open(res, '_blank')
  }

  putLiberarRecepcion (params) {
    return http.put(`${baseUrl}/recepcion/liberar-recepcion`, params, {
      headers: {
        loading: true
      }
    })
  }

  cerrarLineasPedidoSap (body) {
    return http.put(`${baseUrl}/pedidos/close-lines`, body, {
      headers: {
        loading: true
      }
    })
  }

  deleteNovedad = (id) => {
    return http.put(`${baseUrl}/novedades/delete-nov/${id}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  getUsuarioAsignados = () => {
    return http.get(`${baseUrl}/recepcion/usuarios-asignados`, {
      headers: {
        loading: true
      }
    })
  }

  getDistribucionBorrador = (params) => {
    return http.get(`${baseUrl}/recepcion/get-distribucion/${params.LineNum}/${params.DocNum}/${params.idDetalle}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  getDistribucionBodega = (params) => {
    return http.get(`${baseUrl}/pedidos/get-distribucion/${params.DocNum}/${params.LineNum}`, {}, {
      headers: {
        loading: true
      }
    })
  }

  postDistribucion (payload) {
    return http.post(`${baseUrl}/distribucion/create`, payload, {
      headers: {
        loading: true
      }
    })
  }

  removeDistribucion (id) {
    return http.put(`${baseUrl}/distribucion/remove/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  createDevolucion (payload) {
    return http.post(`${baseUrl}/entrada/create-purchase-return`, payload, {
      headers: {
        loading: true
      }
    })
  }

  createCorreccion (payload) {
    return http.post(`${baseUrl}/entrada/correccion-entrada-mercancia`, payload, {
      headers: {
        loading: true
      }
    })
  }

  postConfig (payload) {
    return http.post(`${baseUrl}/configuracion/create-config`, payload, {
      headers: {
        loading: true
      }
    })
  }

  getConfig (params) {
    return http.get(`${baseUrl}/configuracion/get-config`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  deleteConfig (payload) {
    return http.put(`${baseUrl}/configuracion/delete-config/${payload}`, {
      headers: {
        loading: true
      }
    })
  }

  getDetalleDevolucion (DocEntry) {
    return http.get(`${baseUrl}/devolucion/get-devolucion/${DocEntry}`, {
      headers: {
        loading: true
      }
    })
  }

  cancelarDevolucion (DocEntry, payload) {
    return http.post(`${baseUrl}/devolucion/cancelar-devolucion/${DocEntry}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  getPedidoFactura ({ DocNum }) {
    return http.get(`${baseUrl}/recepcion-admin/get-pedido-factura/${DocNum}`, {
      headers: {
        loading: true
      }
    })
  }

  getDetailsXml (id) {
    return http.get(`${baseUrl}/recepcion-admin/get-recepcion-administrativa/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  searchMd (params) {
    return http.get(`${baseUrl}/recepcion-admin/search-md`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  postRecepcionAdmin (payload) {
    return http.post(`${baseUrl}/recepcion-admin/post-recepcion-admin/`, payload, {
      headers: {
        loading: true
      }
    })
  }

  paginateXml (params) {
    return http.get(`${baseUrl}/recepcion-admin/get-paginate-factura`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  deleteRecepcionAdmin = (id) => {
    return http.put(`${baseUrl}/recepcion-admin/delete-recepcion-admin/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  getEditRecepcionAdmin = (id) => {
    return http.get(`${baseUrl}/recepcion-admin/get-edit-recepcion-admin/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  updateRecepcionAdmin (payload) {
    return http.put(`${baseUrl}/recepcion-admin/update-recepcion-admin/${payload.id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  deleteLoteRecepcionAdmin = (id) => {
    return http.put(`${baseUrl}/recepcion-admin/delete-lote-recepcion-admin/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  /*****************
   * Cargue Facturas
   ****************/

  /**
   * Crear preliminar de entrada
   * @param payload
   * @return {*}
   */
  crearPreliminarEntrada (payload) {
    return http.post(`${baseUrl}/cargue-facturas/create-preliminar-entrada/`, payload, {
      headers: {
        loading: true
      }
    })
  }

  /**
   * Listar preliminares de entrada
   * @param params
   * @return {*}
   */
  listarPreliminaresEntrada (params) {
    return http.get(`${baseUrl}/cargue-facturas/listar`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  /**
   * Obtener la información de la preliminar de entrada
   * @param id
   * @return {*}
   */
  getPreliminarEntrada (id) {
    return http.get(`${baseUrl}/cargue-facturas/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  /**
   * Listar proveedores
   * @param id
   * @return {*}
   */
  listarProveedores () {
    return http.get(`${baseUrl}/cargue-facturas/listar-proveedores`, {
      headers: {
        loading: true
      }
    })
  }

  /**
   * Obtener los pedidos de la factura
   * @param params
   * @return {*}
   */
  getPedidosFactura (params) {
    return http.get(`${baseUrl}/cargue-facturas/get-pedidos-factura`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  /**
   * Buscar y obtener los medicamentos por codigo CUM
   * @param params
   * @return {*}
   */
  getAllMdsByCum (params) {
    return http.get(`${baseUrl}/cargue-facturas/get-all-mds-cums`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  /**
   * Obtener cums de disfarma enviando los codigos
   * @param params
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  getCumsDisfarma (params) {
    return http.get(`${baseUrl}/cargue-facturas/get-cums-disfarma`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  /**
   * Verificar si existe la factura y fue enviada a SAP
   * @param params
   * @return {*}
   */
  checkExistFactura (params) {
    return http.get(`${baseUrl}/cargue-facturas/check-exist-factura`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
